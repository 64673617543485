@import url("https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Sora", sans-serif;
  }
}

@layer utilities {
  body {
    color: white;
  }

  .min-h-calc-screen-minus-navbar {
    min-height: calc(100vh - 4.75rem);
  }

  .h-calc-screen-minus-navbar {
    height: calc(100vh - 4.75rem);
  }

  .h-chat-mobile {
    height: calc(100vh - 12.5rem);
  }

  .h-playground-chat-mobile {
    height: calc(100vh - 22.25rem);
  }

  .h-playground-chat {
    height: calc(100vh - 21.25rem);
  }

  .h-sidebar-menu {
    height: calc(100vh - 20.75rem);
  }

  .h-sidebar-menu-hidden {
    height: calc(100vh - 25.5rem);
  }

  .h-configuration-list {
    height: calc(100vh - 23.125rem);
  }

  .h-configuration-desktop {
    height: calc(100vh - 25.5rem);
  }

  .h-integration-tablet {
    height: calc(100vh - 4rem);
  }

  .h-integration {
    height: calc(100vh - 8rem);
  }

  .h-chat-history {
    height: calc(100% - 88px);
  }

  .h-new-feature {
    height: calc(100% - 440px - 88px);
  }

  .h-search-part {
    height: calc(100% - 440px - 105px);
  }

  /* Chrome, Safari and Opera */
  .scrollbar-none::-webkit-scrollbar {
    display: none;
  }

  .scrollbar-none {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .no-scroll {
    overflow: hidden;
  }
}

/* styles for splide carousel */
@layer components {
  .splide-custom .splide__arrow {
    @apply relative top-0 left-0 right-0 flex items-center justify-center w-12 h-12 bg-transparent border border-solid border-slate-gray/50 rounded-full transform-none transition-colors hover:border-lavender-gray;
  }

  .splide-custom .splide__arrow:hover svg {
    @apply fill-pure-white;
  }

  .splide-custom .splide__arrow svg {
    @apply w-4 h-4 fill-slate-gray transform-none transition-colors;
  }

  .splide-visible .splide__track {
    @apply overflow-visible;
  }

  .splide-pricing .splide__list {
    @apply xl:grid xl:items-center !important;
    @apply xl:grid-cols-3 lg:gap-4;
    @apply flex lg:justify-center;
  }

  .splide-benefits .splide__list {
    @apply flex;
  }

  .splide__sr {
    @apply bg-red-500;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: theme("colors.slate-gray");
}

input.autofill-error:-webkit-autofill,
input.autofill-error:-webkit-autofill:hover,
input.autofill-error:-webkit-autofill:focus,
input.autofill-error:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: theme("colors.error-red");
}

.Toastify__toast {
  font-family: "Sora", sans-serif;
  color: white;
  border-radius: 0.75rem;
  box-shadow: 0px 0px 24px 4px rgba(0, 0, 0, 0.05), 0px 32px 48px -4px rgba(0, 0, 0, 0.2);
}

.Toastify__toast--success {
  border: 0.75px solid #3fdd78;
  background: #232f1b;
}

.Toastify__toast--error {
  border: 0.75px solid #d84c10;
  background: #2e1b1b;
}

.Toastify__close-button {
  color: white;
}
